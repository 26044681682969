@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


:root {
    --base-color: #fafafa;
    --bs-gray: #888;
    --bs-gray-100: #f5f4f7;
    --bs-gray-300: #ccc;
    --bs-gray-600: #444;
    --bs-gray-900: #111;
    --bs-primary: #6941cb;
    --heading-color: #000;
    --white-color: #fff;
    --tr-bg-color: #daccfc;
}

body {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: var(--bs-gray-900);
    font-weight: 400;
    background: var(--base-color);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    color: #2c2b2e;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;

}

h1,
.h1 {
    font-size: 40px;
}

h2,
.h2 {
    font-size: 32px;
}

h3,
.h3 {
    font-size: 28px;
}

h4,
.h4 {
    font-size: 24px;
}

h5,
.h5 {
    font-size: 16px;
}

p {
    margin-bottom: 20px;
}

a {
    color: #7460ee;
    text-decoration: none;
    background-color: transparent;
}

.btn-primary {
    color: #fff;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    padding: 12px 30px;
    font-size: 14px;
    min-width: 150px;
    border-radius: 10px;
}

.btn-danger {
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 14px;
    border-radius: 4px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #6352ca;
    border-color: #6352ca;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 #8978f180;
    background-color: #6352ca;
    border-color: #5d4dbe;
}

.btn-secondary {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
    padding-top: 12px;
    font-size: 14px;
    border-radius: 10px;
    padding-bottom: 12px;
    min-width: 150px;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #989fa6;
    border-color: #9299a1;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: 0 0 #72798080;
    background-color: #989fa6;
    border-color: #9299a1;
}

label {
    font-weight: 600;
    font-size: 16px;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(2em + 0.75rem + 2px);
    padding: 10px 19px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-gray-900);
    background-color: var(--bs-gray-100);
    background-clip: padding-box;
    border: 1px solid var(--bs-gray-100);
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #54667a;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(26 188 156 / 25%);
}

.form-control::-webkit-input-placeholder {
    color: var(--bs-gray-900);
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: var(--bs-gray-900);
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: var(--bs-gray-900);
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: var(--bs-gray-900);
    opacity: 1;
}

.form-control::placeholder {
    color: var(--bs-gray-900);
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.125);
    margin-bottom: 30px;
    transition: box-shadow 0.2s ease-in-out;
}

.card .card-header {
    border-bottom: 1px solid #e2e5e8;
    position: relative;
    padding: 20px;
    background: none;
}

.card .card-body {
    flex: 1 1 auto;
    padding: 20px;
}

.navbar-toggler {
    border: 1px solid #fff;
    padding: 4px;
}

.navbar-toggler-icon {
    background: url('assets/img/icon-menu.svg') no-repeat;
    background-size: contain;
}

.login-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    min-width: 100%;
    min-height: 100vh;
    background: var(--white-color);

}

.login-content {
    width: 400px;
    padding: 15px;
}

.login-inner {
    width: 50%;
}

.login-inner .login-box {
    height: 100%;
}

.login-inner.left {
    background-color: var(--bs-gray-900);
}

.signin-form .form-group .form-control {
    /* width: 50px; */
    height: 50px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin: 0 4px;
    padding: 0;
}

.login-content h3 {
    font-weight: 600;
}

.navbar {
    background-color: #272b34;
    transition: all 0.3s ease-in-out;
}

.navbar .nav-link {
    color: rgba(255, 255, 255, 0.6);
}

.navbar .nav-link i {
    padding-right: 10px;
}

.navbar .nav-link:hover,
.navbar .nav-item.active a {
    color: #fff;
}

.content-wrapper {
    margin-top: 30px;
    transition: .3s ease-in-out, width .3s ease-in-out;
}

.table {
    border: none;
    box-shadow: none;
}

.table thead th {
    color: #212529;
    border-bottom: 1px solid var(--bs-gray-100);
    box-shadow: none;
    /* text-transform: uppercase; */
}

.table th,
.table td {
    padding: 16px;
    font-size: 14px;
    border: none;
}

.table tbody tr:nth-child(even) td {
    background-color: var(--tr-bg-color);
}


.btn-action a {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.btn-action a.view i svg,
.btn-action a.delete i svg,
.btn-action a.icon-download i svg {
    width: 22px;
}

.btn-action a i svg {
    width: 20px;
    stroke: #54667a;
}

.btn-action a.delete i svg,
.btn-action a.icon-download i svg {
    stroke: none;
    fill: #54667a;
}

.btn-action a:hover i svg {
    stroke: #7460ee;
}

.btn-action a.delete:hover i svg,
.btn-action a.icon-download:hover i svg {
    fill: #7460ee;
    stroke: none;
}

.modal-header,
.modal-content {
    border-radius: 2px;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: scale(0.7);
}

.modal.show .modal-dialog {
    transform: none;
}

.page-link {
    color: var(--bs-primary);

}

.page-item.disabled .page-link {
    color: #6c757d;
}

.page-item.active .page-link {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

.page-item .page-link {
    border-radius: 50%;
    margin-right: 10px;
    width: 40px;
    padding: 0;
    line-height: 40px;
    height: 40px;
    text-align: center;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    border-radius: 50%;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0rem rgba(26, 188, 156, 0.25);
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #bdb3ff;
    border-color: #bdb3ff;
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 15px;
}

.custom-control-label::before {
    position: absolute;
    top: 0;
    left: -1.5rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: "";
    background-color: var(--bs-gray-100);
    border: var(--bs-gray-100) solid 2px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.05rem;
    left: -1.5rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    content: "";
    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #1abc9c;
    background-color: #1abc9c;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(26, 188, 156, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(26, 188, 156, 0.5);
}

.nav-item .dropdown-menu {
    box-shadow: 0 4px 24px 0 rgb(62 57 107 / 18%);
    border: none;
}

.nav-item .dropdown-menu {
    background: #272b34;
}

.nav-item .dropdown-menu a {
    font-size: 14px;
    color: rgb(255, 255, 255, 0.6);
}

.nav-item .dropdown-menu {
    top: 44px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.nav-item .dropdown-menu a i {
    padding-right: 10px;
}

.nav-item .dropdown-item:focus,
.nav-item .dropdown-item:hover {
    background-color: transparent;
    color: #fff;
}

.dropdown-toggle::after {
    width: 10px;
    height: 10px;
    transform: rotate(-135deg);
    background: transparent;
    text-indent: -9999px;
    border-top: 2px solid rgb(255, 255, 255, 0.6);
    border-left: 2px solid rgb(255, 255, 255, 0.6);
    transition: all 250ms ease-in-out;
    text-decoration: none;
    color: transparent;
    border-right: none;
    vertical-align: baseline;
    cursor: pointer;

}

.navbar .nav-link:hover .dropdown-toggle::after,
.navbar .nav-item.active a.dropdown-toggle::after {
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
}

.navbar .nav-item.active .dropdown-menu a {
    color: rgb(255, 255, 255, 0.6);
}

.navbar .nav-item.active .dropdown-menu a:hover {
    color: #fff;
}

.modal-body .form-group {
    margin-bottom: 1rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.upload-logo input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-logo span {
    font-size: 12px;
    font-weight: 400;
    color: #8e919d;
}

.upload-logo p {
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 3px;
    color: #3a43c0;
}

.upload-logo {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    border: 1px dashed #9ba1b5;
}

.spinner {
    position: relative !important;
}

.clickable-pointer {
    cursor: pointer;
}

.manual-deposit-table {
    overflow-x: auto;
}

.manual-deposit-table .dropdown-menu {
    padding: 5px 10px;
}

.manual-deposit-table .dropdown-item {
    font-size: 14px;
    padding: 5px;
}

.logo {
    text-align: center;
}

.logo img {
    max-width: 160px;
    margin-bottom: 10px;
}

.logo span {
    display: block;
    font-size: 40px;
    color: var(--white-color);
}

.input-group .input-group-text {
    background-color: var(--bs-gray-100);
    background-repeat: no-repeat;
    background-position: 12px center;
    width: 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.input-group .form-control {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: rgb(206, 212, 218);
    border-width: 1px;
    border-style: solid;
}

.input-group.input-email-group .input-group-text {
    background-image: url('assets/img/icon-email.svg');
    background-size: 18px;
}

.input-group.input-phone-group .input-group-text {
    background-image: url('assets/img/icon-lock.svg');
    background-size: 16px;
}

.main-sidebar {
    background-color: var(--bs-gray-900);
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    bottom: 0;
    min-height: 100vh;
    padding-top: 20px;
    z-index: 100;
    transition: .3s ease-in-out, width .3s ease-in-out;
}

.sidebar-collapse .main-sidebar {
    left: 0px;
}

.sidebar-close {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    border: none;
}

.sidebar-logo {
    display: block;
    text-align: center;
}

.sidebar-logo img {
     height: 50px;
}

.sidebar-menu {
    list-style: none;
    text-align: center;
    margin: 50px auto 0;
    width: 75%;
    padding: 0;
}

.sidebar-menu li {
    margin-bottom: 10px;
}

.sidebar-menu li a {
    display: block;
    padding: 12px;
    color: var(--white-color);
    border-radius: 20px;
}

.sidebar-menu li a.active {
    background-color: var(--bs-gray-600);
}

.sidebar-menu li a:hover,
.sidebar-menu li a:focus {
    background-color: var(--bs-primary);
    color: var(--white-color);
}

.header {
    background: var(--white-color);
    height: 60px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 0 15px;
    transition: .3s ease-in-out, width .3s ease-in-out;
}

.navbar-toggle {
    background-color: var(--bs-gray-100);
    border: none;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    text-align: center;

}

.logout {
    color: var(--bs-gray-900);
}

.logout i {
    margin-right: 10px;
}

.logout i {
    margin-left: 10px;
    vertical-align: middle;
}

.content-wrapper h2 {
    font-size: 25px;
}

.radio-container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: var(--bs-gray-100);
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input~.checkmark {
    background-color: var(--bs-gray-300);
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.checkmark {
    background-color: var(--bs-primary);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    top: 10px;
    left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--white-color);
}

.bg-gray {
    background-color: var(--bs-gray-100);
}

.thank-you-img { 
    display: block;
    max-width: 250px;
    margin: 0 auto;
}

.sidebar-logo .nav-link.dropdown-toggle {
    font-size: 0px;
    /* position: absolute;
    right: 70px;
    top: -30px; */
    margin-left: 20px;
}

.sidebar-logo .dropdown-menu {
    position: static !important;
    transform: none !important ;
    margin: 0px auto !important;
    border-radius: 0px;
    width: 80%;
    text-align: center;
    color: var(--white-color);
    background-color: transparent !important;
}
.sidebar-logo .dropdown-menu hr{ border:2px solid var(--bs-gray-300)}

.sidebar-logo .dropdown-item {
     color: var(--white-color);
     padding: 12px;
     border-radius:20px;
     cursor: pointer;
     margin-bottom: 10px;
}
.sidebar-logo .dropdown-item:hover {
    background-color: var(--bs-primary);
}

.nav-tabs .nav-link{
    font-size: 16px;
    color: var(--bs-gray-900);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-primary);
}

.tab-content .tab-pane {
    padding:20px 0;
}

.tab-content .tab-pane .form-group label {
    padding: 7px 0;
}
.org-name {
    display: block;
    color: var(--white-color);
    margin-top: 20px;
    text-align: center;
}


@media only screen and (min-width:992px) {
    .manual-deposit-table {
        overflow-x: inherit;
    }

    .main-sidebar {
        margin-left: 0px;
        left: 0px;
    }

    .content-wrapper {
        margin-left: 270px;
        margin-right: 20px;
    }

    .content-wrapper h2 {
        font-size: 32px;
    }

    .header {
        margin-left: 250px;
        padding: 0 30px;
        height: 90px;
    }

    .sidebar-collapse .main-sidebar {
        margin-left: -250px;
        width: auto;
    }

    .sidebar-close {
        display: none;
    }

    .sidebar-collapse .content-wrapper {
        margin-left: 20px;
    }

    .sidebar-collapse .header {
        margin-left: 0px;
    }
}

@media only screen and (max-width:768px) {

    .form-wrapper .form-group {
        width: 48.9%;
    }

    .form-wrapper .form-group:nth-child(3),
    .form-wrapper .form-group.form-group-wrap {
        width: 100%;
    }



    .login-content {
        width: 350px;
    }

    .signin-form .form-group .form-control {
        /* width: 42px; */
        height: 42px;
        font-size: 16px;
        line-height: 42px;
    }

    .login-wrapper {
        flex-direction: column;
        justify-content: center;
    }

    .login-inner {
        width: 100%;
    }

    .login-inner.left {
        background-color: transparent;
    }

    .logo {
        width: 80%;
        border-bottom: 1px solid var(--bs-gray-600);
        padding-bottom: 20px;
        margin-bottom: 40px;
    }

    .logo img {
        max-width: 110px;
    }

    .logo span {
        font-size: 30px;
        color: var(--bs-gray-900);
    }

}

@media only screen and (min-width:768px) {
    .navbar-expand-md .navbar-nav .nav-link {
        font-size: 12px;
    }

}

@media only screen and (min-width:1200px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 2rem;
        padding-left: 2rem;
        font-size: 14px;
    }

}